/* eslint-disable prefer-destructuring */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from "react";

import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import Color from "color";
import { connect, useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { addItemToCartSuccess } from "../components/@pages/storeHome/MyOrder/redux/actions";
import Layout from "../components/Layout";
import withInitDataFetch from "../components/Layout/withFetch";
import ProtectedRoute from "../components/routes/ProtectedRoute";
import ConfirmPage from "../pages/ConfirmPage";
import ContactPage from "../pages/ContactPage";
import DeliveryPage from "../pages/DeliveryPage";
import ItemPopupModal from "../pages/ItemPopupModal";
import PaymentsPage from "../pages/Payments";
import StoreHomePage from "../pages/storeHomePage";
import { logInfo } from "../services/logging";
import getThemeConfigOuter from "../theme/main";
import { getLocalStore, setLocalStore } from "../utils/storage/localStorage";
import "../utils/time/moment";
import MyOrdersPage from "../pages/MyOrdersPage";
import OrderStatusPage from "../pages/OrderStatusPage";
import NewOrderStatusPage from "../pages/NewOrderStatusPage";
import LoginPage from "../components/@pages/loginPage";
import SignInPage from "../components/@pages/signInPage/SignInPage";
import RegisterPage from "../components/@pages/registerPage";
import OrderSuccessPage from "../pages/OrderSuccessPage";

const WHITE_PURE = "#FFFFFF";
const BLACK_PURE = "#000000";

export const BORDER_RADIUS = 0;

const LayoutWithData = withInitDataFetch(Layout);

function App({ initData }) {
  const { theme } = initData;
  const [prestoTheme, setPrestoTheme] = useState(createTheme({}));

  const dispatch = useDispatch();

  const actionTextColor = theme.actionTextColor || BLACK_PURE;

  const titleTextColor = theme.titleTextColor || BLACK_PURE;
  const subtitleTextColor = theme.subtitleTextColor || BLACK_PURE;
  const itemTextColor = theme.itemTextColor || BLACK_PURE;
  const itemDescColor = theme.itemDescColor || BLACK_PURE;
  const menuTextColor = theme.menuTextColor || BLACK_PURE;
  const accentTextColor = theme.accentTextColor || BLACK_PURE;
  const primaryTextColor = theme.primaryTextColor || BLACK_PURE;
  const secondaryTextColor = theme.secondaryTextColor || BLACK_PURE;

  const menuBackgroundColor = theme.menuBackgroundColor || WHITE_PURE;
  const categoryBackgroundColor = theme.categoryBackgroundColor || WHITE_PURE;
  const accentColor = theme.accentColor || WHITE_PURE;

  const primaryColor = theme.primaryColor || BLACK_PURE;
  const secondaryColor = theme.secondaryColor || BLACK_PURE;
  const boarderColor = theme.boarderColor || BLACK_PURE;

  const defaultTextColor = primaryTextColor;

  const isDark = Color(primaryColor).isDark();

  const viewLayoutType = theme.viewLayoutType;

  const borderRadius = 8;

  useEffect(() => {
    const config = getThemeConfigOuter(
      defaultTextColor,
      primaryTextColor,
      actionTextColor,
      titleTextColor,
      subtitleTextColor,
      itemTextColor,
      itemDescColor,
      menuTextColor,
      accentTextColor,
      secondaryTextColor,

      primaryColor,
      secondaryColor,

      menuBackgroundColor,
      categoryBackgroundColor,
      accentColor,

      boarderColor,

      isDark,

      viewLayoutType,

      borderRadius,
    );
    const preTheme = responsiveFontSizes(createTheme({ ...config }));
    setPrestoTheme(preTheme);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme]);

  const generateSessionId = () => {
    try {
      let sessionId = getLocalStore("sessionId");
      if (sessionId) return;
      sessionId = uuidv4();
      setLocalStore("sessionId", sessionId);
      logInfo({
        message: `New session initiated with session id: ${sessionId}`,
      });
    } catch (error) {
      console.error("ERROR: INCOGNITO MODE");
    }
  };

  useEffect(() => {
    const handleAddCartMessage = (event) => {
      const isAllowed =
        process.env.REACT_APP_RESTAURANT_DOMAIN === "*" ||
        event.origin === process.env.REACT_APP_RESTAURANT_DOMAIN;

      if (isAllowed) {
        const { addItemToCart, cartItemNode } = event?.data;
        if (addItemToCart) {
          dispatch(addItemToCartSuccess(cartItemNode));
        }
      }
    };

    window.addEventListener("message", handleAddCartMessage, false);
    generateSessionId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!theme) return null;

  return (
    <ThemeProvider theme={prestoTheme}>
      <Router>
        <Switch>
          <Route
            key="login"
            exact
            path="/location/:locationId/login"
            component={LoginPage}
          />

          <Route
            key="location"
            path="/location/:locationId"
            render={() => {
              if (window.location.pathname.includes("itempopupmodal")) {
                return (
                  <LayoutWithData>
                    <Route
                      key="item-modal"
                      exact
                      path="/location/:locationId/itempopupmodal"
                      component={ItemPopupModal}
                    />
                  </LayoutWithData>
                );
              }
              return (
                <>
                  <LayoutWithData>
                    <Route
                      key="login"
                      exact
                      path="/location/:locationId/signIn"
                      component={SignInPage}
                    />
                    <Route
                      key="login"
                      exact
                      path="/location/:locationId/register"
                      component={RegisterPage}
                    />
                    <Route
                      key="store-home"
                      exact
                      path="/location/:locationId/shop"
                      component={StoreHomePage}
                    />

                    <ProtectedRoute
                      key="delivery"
                      exact
                      path="/location/:locationId/delivery"
                      component={DeliveryPage}
                    />
                    <ProtectedRoute
                      key="contact"
                      exact
                      path="/location/:locationId/contact"
                      component={ContactPage}
                    />
                    <ProtectedRoute
                      key="payments"
                      exact
                      path="/location/:locationId/payments"
                      component={PaymentsPage}
                    />
                    <ProtectedRoute
                      key="confirm"
                      exact
                      path="/location/:locationId/confirm"
                      component={ConfirmPage}
                    />
                    <Route
                      key="order-status"
                      exact
                      path="/location/:locationId/order-status/:orderId"
                      component={OrderStatusPage}
                    />
                     <Route
                      key="order-orders"
                      exact
                      path="/location/:locationId/order-success/:orderId"
                      component={OrderSuccessPage}
                    />
                    <Route
                      key="my-orders"
                      exact
                      path="/location/:locationId/my-orders"
                      component={MyOrdersPage}
                    />
                    <Route
                      key="my-orders"
                      exact
                      path="/location/:locationId/my-orders/:orderId"
                      component={NewOrderStatusPage}
                    />
                   
                  </LayoutWithData>
                </>
              );
            }}
          />
          <Redirect
            from="/"
            to="/location/7713dce7-51ec-4870-b251-4bce1c9249f8/shop"
          />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

// function RouteWrapper({ component: Component, ...rest }) {
//   return (
//     <Route
//       {...rest}
//       render={(props) => (
//         <LayoutWithData {...props}>
//           <Component {...props} />
//         </LayoutWithData>
//       )}
//     />
//   );
// }

const mapStateToProps = (state) => ({
  initData: state.initData,
});

export default connect(mapStateToProps, null)(App);
