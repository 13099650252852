import React, { useEffect, useState } from "react";

import {
  Badge,
  Box,
  Button,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  Grid,
  RadioGroup,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import PanoramaFishEyeIcon from "@material-ui/icons/PanoramaFishEye";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import _ from "lodash";
import { useSelector } from "react-redux";
import styled from "styled-components";

import noImageDark from "../../../../../../assets/images/placeholder_dark.png";
import noImageLight from "../../../../../../assets/images/placeholder_light.png";
import { getIsItemActive } from "../../../../../../utils/availability";
import { formatPrice, getPriceByPcp } from "../../../../../../utils/utils";
import ErrorBoundary from "../../../../../common/error/ErrorBoundary";
import {
  IMAGE_GRID_OPTION,
  IMAGE_LIST_OPTION,
  MENU_VIEW_TYPE_GRID,
} from "../../../../../../utils/constants";

const FullWidthFormControlLabel = styled(FormControlLabel)`
  .MuiFormControlLabel-label {
    width: 100%;
    flex: 1;
    @media (max-width: 600px) {
      margin-right: 0;
    }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    padding: theme.spacing(1),
    flex: 1,
    justifyContent: "space-between",
  },
  label: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    color: theme.palette.text.itemTextColor,
  },
  labelNew: {
    width: "100%",
    color: theme.palette.text.itemTextColor,
    display: "flex",
    justifyContent: "center",
  },
  price: {
    // marginLeft: theme.spacing(2),
  },
  subItem: {
    width: "48%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  stageTitle: {
    color: theme.palette.text.titleTextColor,
  },
  checkbox: {
    color: theme.palette.primary.main,
  },
  itemLabelStyle: {
    "&:hover": {
      backgroundColor: "inherit", // No background color change on hover
      boxShadow: "none", // Remove shadow on hover (if any)
    },
  },
  itemLabelStyleNew: {
    display: "flex",
    justifyContent: "center",
    "&:hover": {
      backgroundColor: "inherit", // No background color change on hover
      boxShadow: "none", // Remove shadow on hover (if any)
    },
  },
  badge: {
    zIndex: 0,
    "& .MuiBadge-badge": {
      transform: "translate(-84%, -94%)", // Moves badge to align with the image's end
    },
  },
  itemImage: {
    borderRadius: "10px",
    // border: `1px solid ${theme.palette.text.secondary}`,
  },
}));

const SubProductStage = ({
  stage,
  subProdData,
  subItems,
  setSubItems,
  errors,
  expandedStageGroups,
  catMenuOptions,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const classes = useStyles();
  const error = errors.find((e) => e.stage === stage);
  // eslint-disable-next-line camelcase
  const { mealDealPriceChangePoint, mealDealMax } = subProdData;
  const { activeCatMenu } = useSelector((state) => state.catMenu);
  const {
    theme: { itemDisplayType, optionDisplayType },
  } = useSelector((state) => state.initData);
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const isDarkTheme = (primaryColor) => {
    const rgb = parseInt(primaryColor.substring(1), 16); // Remove # and parse as hex
    const r = (rgb >> 16) & 0xff; // Red
    const g = (rgb >> 8) & 0xff; // Green
    const b = (rgb >> 0) & 0xff; // Blue
    const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b; // Relative luminance formula
    return luminance < 128; // Dark if luminance is less than 128
  };

  const isDark = isDarkTheme(theme.palette.primary.main);

  useEffect(() => {
    if (_.isEmpty(subProdData.stageGroup)) {
      setIsOpen(true);
    }
  }, [subProdData]);

  useEffect(() => {
    if (!isOpen) {
      const subItemNew = Object.assign([], subItems);
      subItemNew[stage].subs = [];
      setSubItems(subItemNew);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const handleSelectSubItem = async (sub, e) => {
    sub["stageId"] = subProdData.itemId;
    const subPriced = { ...sub };
    subPriced.price = getPriceByPcp(
      subItems[stage].subs,
      mealDealPriceChangePoint,
      sub.extraCost,
      sub.surcharge,
      sub.itemId,
    );
    const { checked } = e.target;
    const subItemNew = Object.assign([], subItems);
    if (checked) {
      // check whether stage can have only one selection
      if (mealDealMax === 1) {
        subItemNew[stage].subs = [subPriced];
      } else {
        // add new sub item to main prod array
        subItemNew[stage].subs.push(subPriced);
      }
    } else {
      subItemNew[stage].subs = subItemNew[stage].subs.filter(
        (s) => s.itemId !== subPriced.itemId,
      );
    }
    setSubItems(subItemNew);
  };

  const handleSelectSubItemGrid = async (sub, checked) => {
    sub["stageId"] = subProdData.itemId;
    const subPriced = { ...sub };
    subPriced.price = getPriceByPcp(
      subItems[stage].subs,
      mealDealPriceChangePoint,
      sub.extraCost,
      sub.surcharge,
      sub.itemId,
    );
    const subItemNew = Object.assign([], subItems);
    if (checked) {
      subItemNew[stage].subs = subItemNew[stage].subs.filter(
        (s) => s.itemId !== subPriced.itemId,
      );
    } else {
      // check whether stage can have only one selection
      if (mealDealMax === 1) {
        subItemNew[stage].subs = [subPriced];
      } else {
        // add new sub item to main prod array
        subItemNew[stage].subs.push(subPriced);
      }
    }
    setSubItems(subItemNew);
  };

  useEffect(() => {
    if (!_.isEmpty(subProdData.stageGroup)) {
      if (expandedStageGroups.includes(subProdData.stageGroup)) {
        // const subItemNew = Object.assign([], subItems);
        // subItemNew[stage].subs = [];
        // setSubItems(subItemNew);
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedStageGroups]);

  if (!subItems.length) return null;

  const ItemLabel = ({ title, extraCost, surcharge, itemId }) => (
    <Box component="div" className={classes.label}>
      <Typography variant="caption">{title}</Typography>
      <Typography className={classes.price} variant="subtitle2">
        {formatPrice(
          getPriceByPcp(
            subItems[stage].subs,
            mealDealPriceChangePoint,
            extraCost,
            surcharge,
            itemId,
          ),
        )}
      </Typography>
    </Box>
  );

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  };

  const convertThumbCloudinary = (image) => {
    if (image.includes("res.cloudinary.com")) {
      const [firstPart, secondPart] = image.split("/upload/");
      return `${firstPart}/upload/t_presto_cover/${secondPart}`;
    }
    return image;
  };

  const ItemLabelGridNew = ({
    title,
    extraCost,
    surcharge,
    itemId,
    imageUrl,
    checked,
  }) => (
    <Box component="div" className={classes.labelNew}>
      <div style={{ paddingLeft: "12px" }}>
        <div>
          <Badge
            className={classes.badge}
            badgeContent={
              checked ? (
                <CheckCircleIcon
                  style={{
                    // backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.main,
                    fontSize: "32px",
                  }}
                />
              ) : (
                <></>
                // <div
                //   style={{
                //     display: "flex",
                //     alignItems: "center",
                //     justifyContent: "center",
                //     width: 24, // Adjust as needed
                //     height: 24, // Adjust as needed
                //     backgroundColor: theme.palette.background.menuBackgroundColor, // Background color
                //     borderRadius: "50%", // Makes it circular
                //     zIndex: 0,
                //   }}
                // >
                //   <PanoramaFishEyeIcon
                //     style={{
                //       color: theme.palette.secondary.main,
                //       fontSize: "36px",
                //     }}
                //   />
                // </div>
              )
            }
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            overlap="circular"
          >
            {/* // <img
            //   width="44px"
            //   height="44px"
            //   src={imageUrl}
            //   alt="Not available image"
            // /> */}
            <img
              className={classes.itemImage}
              id={`menuItemDisplayImage${itemId}`}
              onError={() => {
                document.getElementById(`menuItemDisplayImage${itemId}`).src =
                  isDark ? noImageDark : noImageDark;
              }}
              onLoad={handleImageLoad}
              alt="menu item"
              src={
                imageUrl
                  ? convertThumbCloudinary(imageUrl)
                  : isDark
                  ? noImageDark
                  : noImageDark
              }
              width="96px"
              height="auto"
              title="menu item"
            />
          </Badge>
          <Typography
            style={{
              textTransform: "none",
              fontSize: "14px",
              marginTop: "10px",
            }}
          >
            {title}
          </Typography>
          <Typography
            className={classes.price}
            style={{ textTransform: "none", fontSize: "14px", lineHeight: 1 }}
          >
            {formatPrice(
              getPriceByPcp(
                subItems[stage].subs,
                mealDealPriceChangePoint,
                extraCost,
                surcharge,
                itemId,
              ),
            )}
          </Typography>
        </div>
      </div>
    </Box>
  );

  const ItemLabelGrid = ({
    title,
    extraCost,
    surcharge,
    itemId,
    imageUrl,
    checked,
  }) => (
    <Box component="div" className={classes.label}>
      <div style={{ display: "flex" }}>
        <div>
          <Badge
            className={classes.badge}
            badgeContent={
              checked ? (
                <CheckCircleIcon
                  style={{
                    // backgroundColor: theme.palette.secondary.main,
                    color: theme.palette.secondary.main,
                    fontSize: "22px",
                  }}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 17, // Adjust as needed
                    height: 17, // Adjust as needed
                    backgroundColor:
                      theme.palette.background.menuBackgroundColor, // Background color
                    borderRadius: "50%", // Makes it circular
                    zIndex: 0,
                  }}
                >
                  <PanoramaFishEyeIcon
                    style={{
                      color: theme.palette.secondary.main,
                      fontSize: "24px",
                    }}
                  />
                </div>
              )
            }
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            overlap="circular"
          >
            {imageUrl ? (
              // eslint-disable-next-line jsx-a11y/img-redundant-alt
              // <img
              //   width="44px"
              //   height="44px"
              //   src={imageUrl}
              //   alt="Not available image"
              // />

              <img
                className={classes.itemImage}
                id={`menuItemDisplayImage${itemId}`}
                onError={() => {
                  setIsImageLoaded(true);
                  document.getElementById(`menuItemDisplayImage${itemId}`).src =
                    isDark ? noImageDark : noImageDark;
                }}
                onLoad={handleImageLoad}
                alt="menu item"
                src={convertThumbCloudinary(imageUrl)}
                width="76px"
                height="auto"
                title="menu item"
              />
            ) : (
              <Box style={{ width: "44px", height: "44px" }}></Box>
            )}
          </Badge>
        </div>
        <div style={{ marginLeft: "8px" }}>
          <Typography
            style={{ textTransform: "none", fontSize: "14px", lineHeight: 1 }}
          >
            {title}
          </Typography>
        </div>
      </div>
      <Typography
        className={classes.price}
        style={{ textTransform: "none", fontSize: "14px", lineHeight: 1 }}
      >
        {formatPrice(
          getPriceByPcp(
            subItems[stage].subs,
            mealDealPriceChangePoint,
            extraCost,
            surcharge,
            itemId,
          ),
        )}
      </Typography>
    </Box>
  );

  return (
    <>
      {isOpen && (
        <>
          <FormControl component="fieldset" className={classes.root}>
            <Typography variant="h6" className={classes.stageTitle}>
              {subProdData.title}
            </Typography>
            {error && <Alert severity="error">{error.error}</Alert>}

            {optionDisplayType === IMAGE_GRID_OPTION ? (
              <Grid container spacing={2}>
                {subProdData.subProducts.map((sub, index) => (
                  <ErrorBoundary key={`stage${stage}subItem-${sub.itemId}`}>
                    <Grid
                      item
                      md={3}
                      xs={6}
                      style={
                        isMobile
                          ? {
                              marginTop:
                                index === 0 || index === 1 ? "8px" : "0px",
                            }
                          : {
                              marginTop:
                                index === 0 ||
                                index === 1 ||
                                index === 2 ||
                                index === 3
                                  ? "8px"
                                  : "0px",
                            }
                      }
                    >
                      <Button
                        style={{ width: "100%" }}
                        className={classes.itemLabelStyleNew}
                        disableRipple
                        disableFocusRipple
                        disabled={
                          !getIsItemActive(
                            catMenuOptions,
                            sub.menuId,
                            sub.catId,
                            activeCatMenu.catMenuId,
                          )
                        }
                        onClick={(e) =>
                          handleSelectSubItemGrid(
                            sub,
                            !!subItems[stage]?.subs?.find(
                              (_sub) => _sub.itemId === sub.itemId,
                            ),
                          )
                        }
                      >
                        <ItemLabelGridNew
                          title={sub.title}
                          extraCost={sub.extraCost}
                          surcharge={sub.surcharge}
                          itemId={sub.itemId}
                          imageUrl={sub.imageUrl}
                          checked={
                            !!subItems[stage]?.subs?.find(
                              (_sub) => _sub.itemId === sub.itemId,
                            )
                          }
                        />
                      </Button>
                    </Grid>
                  </ErrorBoundary>
                ))}
              </Grid>
            ) : optionDisplayType === IMAGE_LIST_OPTION ? (
              <Grid container spacing={2}>
                {subProdData.subProducts.map((sub, index) => (
                  <ErrorBoundary key={`stage${stage}subItem-${sub.itemId}`}>
                    <Grid
                      item
                      md={6}
                      xs={12}
                      style={{
                        marginTop: index === 0 || index === 1 ? "4px" : "0px",
                      }}
                    >
                      <Button
                        style={{ width: "100%" }}
                        className={classes.itemLabelStyle}
                        disableRipple
                        disableFocusRipple
                        disabled={
                          !getIsItemActive(
                            catMenuOptions,
                            sub.menuId,
                            sub.catId,
                            activeCatMenu.catMenuId,
                          )
                        }
                        onClick={(e) =>
                          handleSelectSubItemGrid(
                            sub,
                            !!subItems[stage]?.subs?.find(
                              (_sub) => _sub.itemId === sub.itemId,
                            ),
                          )
                        }
                      >
                        <ItemLabelGrid
                          title={sub.title}
                          extraCost={sub.extraCost}
                          surcharge={sub.surcharge}
                          itemId={sub.itemId}
                          imageUrl={sub.imageUrl}
                          checked={
                            !!subItems[stage]?.subs?.find(
                              (_sub) => _sub.itemId === sub.itemId,
                            )
                          }
                        />
                      </Button>
                    </Grid>
                  </ErrorBoundary>
                ))}
              </Grid>
            ) : (
              <RadioGroup
                row
                style={{ justifyContent: "space-between" }}
                aria-label="chooseYourItem"
                name="chooseYourItem"
                defaultValue={subProdData.itemId}
              >
                {subProdData.subProducts.map((sub) => (
                  <ErrorBoundary key={`stage${stage}subItem-${sub.itemId}`}>
                    <FullWidthFormControlLabel
                      className={classes.subItem}
                      id={`stage${stage}subItem-${sub.itemId}`}
                      key={`stage${stage}subItem-${sub.itemId}`}
                      value={sub.itemId}
                      checked={
                        !!subItems[stage]?.subs?.find(
                          (_sub) => _sub.itemId === sub.itemId,
                        )
                      }
                      control={<Checkbox color="primary" />}
                      label={
                        <ItemLabel
                          title={sub.title}
                          extraCost={sub.extraCost}
                          surcharge={sub.surcharge}
                          itemId={sub.itemId}
                        />
                      }
                      disabled={
                        !getIsItemActive(
                          catMenuOptions,
                          sub.menuId,
                          sub.catId,
                          activeCatMenu.catMenuId,
                        )
                      }
                      labelPlacement="end"
                      onChange={(e) => handleSelectSubItem(sub, e)}
                    />
                  </ErrorBoundary>
                ))}
              </RadioGroup>
            )}
          </FormControl>
          <Divider className={classes.divider} variant="middle" />
        </>
      )}
    </>
  );
};

export default SubProductStage;
