/* eslint-disable camelcase */
import _ from "lodash";

import AvailabilityUtils from "./AvailabilityUtils";
import {
  DELIVERY_TYPE_DELIVER,
  DELIVERY_TYPE_DINEIN,
  DELIVERY_TYPE_PICKUP,
} from "./constants";
import moment from "./time/moment";
import TimeUtils from "./TimeUtils";

const DeliveryUtils = {
  /**
   * this will first filter postcode delivery options
   * then it will filter delivery options which are matching with
   * postcode prefix of the delivery address.
   */
  filterPostCodeDeliveryOptions: (
    deliveryOptionsList,
    googleAddress,
    deliveryLocation,
    storeLocation,
  ) => {
    try {
      // filter by matching postcode prefix
      const postCodePrefix =
        DeliveryUtils.__getPostCodePrefixFromAddress(googleAddress);
      const prefixMatchedOptions =
        DeliveryUtils.__filterPostCodeOptionsByPostCodePrefix(
          deliveryOptionsList,
          postCodePrefix,
        );

      // filter options by matching distance if distance key is available on the node
      const filteredListByDistance =
        DeliveryUtils.__filterPostCodeDeliveryOptionsByDistance(
          prefixMatchedOptions,
          deliveryLocation,
          storeLocation,
        );

      return filteredListByDistance;
    } catch (error) {
      return [];
    }
  },

  /**
   * extract postcode and prefix if available and return prefix only.
   */
  __getPostCodePrefixFromAddress: (googleAddress) => {
    const { postal_code, postal_code_prefix } = googleAddress;
    const postalCodePrefix = postal_code
      ? postal_code.split(" ")[0]
      : postal_code_prefix;
    return postalCodePrefix;
  },

  /**
   * filter only POSTCODE type delivery options
   */
  __getPostCodeDeliveryOptions: (deliveryOptionsList) =>
    deliveryOptionsList.filter((option) => option.area.type === "postcode"),

  /**
   * filter only DISTANCE type delivery options
   */
  __getDistanceDeliveryOptions: (deliveryOptionsList) =>
    deliveryOptionsList.filter((option) => option.area.type === "distance"),

  /**
   * filter matching POSTCODE delivery options by address prefix
   */
  __filterPostCodeOptionsByPostCodePrefix: (
    deliveryOptionsList,
    postalPrefix,
  ) => {
    const postCodeDeliveryNodes =
      DeliveryUtils.__getPostCodeDeliveryOptions(deliveryOptionsList);
    const filteredNodeListByPostalPrefix = postCodeDeliveryNodes.filter(
      (option) => Object.keys(option.area.data).includes(postalPrefix),
    );
    return filteredNodeListByPostalPrefix;
  },

  /**
   * filter DISTANCE delivery options by matching delivery options
   * with delivery distance
   */
  filterDistanceDeliveryOptions: (
    deliveryOptionsList,
    deliveryLocation,
    storeLocation,
  ) => {
    const { lat: delLat, lng: delLng } = deliveryLocation;
    const { lat: storeLat, lon: storeLng } = storeLocation;

    const distanceDeliveryNodeList =
      DeliveryUtils.__getDistanceDeliveryOptions(deliveryOptionsList);

    const filteredListByDistance = _.filter(distanceDeliveryNodeList, (o) => {
      const deliverRadius = parseFloat(o.area.data.distance);
      const dist = DeliveryUtils.__getDistanceBetweenPoints(
        parseFloat(delLat),
        parseFloat(delLng),
        parseFloat(storeLat),
        parseFloat(storeLng),
        "M",
      );
      return dist < deliverRadius;
    });

    const minRadiusDeliveryOptions = DeliveryUtils.__getMinimumDistanceOptions(
      filteredListByDistance,
    );
    return minRadiusDeliveryOptions;
  },

  /**
   * this will filter the postal code delivery nodes
   * which distance key is available and matching to
   * that distance. this will make sure to limit the delivery
   * radius even the postal code area is much larger
   */
  __filterPostCodeDeliveryOptionsByDistance: (
    deliveryOptionsList,
    deliveryLocation,
    storeLocation,
  ) => {
    const { lat: delLat, lng: delLng } = deliveryLocation;
    const { lat: storeLat, lon: storeLng } = storeLocation;

    const postCodeNodes =
      DeliveryUtils.__getPostCodeDeliveryOptions(deliveryOptionsList);

    const filteredListByDistance = _.filter(postCodeNodes, (o) => {
      // if no distance key on the node, no need to check the distance
      if (!o?.area?.data?.distance) return true;

      const deliverRadius = parseFloat(o.area.data.distance);
      const dist = DeliveryUtils.__getDistanceBetweenPoints(
        parseFloat(delLat),
        parseFloat(delLng),
        parseFloat(storeLat),
        parseFloat(storeLng),
        "M",
      );
      return dist < deliverRadius;
    });

    const { optionsWithDistance, optionsWithoutDistance } =
      DeliveryUtils.__groupPostCodeOptionsByDistanceKey(filteredListByDistance);

    if (!_.isEmpty(optionsWithDistance)) {
      const minRadiusDeliveryOptions =
        DeliveryUtils.__getMinimumDistanceOptions(optionsWithDistance);
      return minRadiusDeliveryOptions;
    }

    return optionsWithoutDistance;
  },

  /**
   * check whether data of delivery options contains any
   * "distance" key and value for distance (ex: distance: '1.5')
   */
  __groupPostCodeOptionsByDistanceKey: (options) => {
    const { true: optionsWithDistance, false: optionsWithoutDistance } =
      _.groupBy(options, (option) => !!option.area.data.distance);
    return {
      optionsWithDistance,
      optionsWithoutDistance,
    };
  },

  /**
   * get the minimum distance delivery options for the
   * matching delivery distance
   */
  __getMinimumDistanceOptions: (filteredListByDistance) => {
    const minDistance = _.min(
      filteredListByDistance.map((dis) => parseFloat(dis.area.data.distance)),
    );
    return filteredListByDistance.filter(
      (dis) => parseFloat(dis.area.data.distance) === minDistance,
    );
  },

  /**
   * distance calculating between two points
   */
  __getDistanceBetweenPoints: (lat1, lon1, lat2, lon2, unit) => {
    if (lat1 === lat2 && lon1 === lon2) {
      return 0;
    }

    const radlat1 = (Math.PI * lat1) / 180;
    const radlat2 = (Math.PI * lat2) / 180;
    const theta = lon1 - lon2;
    const radtheta = (Math.PI * theta) / 180;
    let dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    if (dist > 1) {
      dist = 1;
    }
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;
    if (unit === "K") {
      dist *= 1.609344;
    }
    if (unit === "N") {
      dist *= 0.8684;
    }
    return dist;
  },

  isPostCodePrefixEmpty: (googleAddress) =>
    _.isEmpty(googleAddress.postal_code_prefix) &&
    _.isEmpty(googleAddress.postal_code),

  isDeliveryDataLoaded: (deliveryLocation, storeInfo) => {
    const { lat, lng } = deliveryLocation;
    return lat && lng && !_.isEmpty(storeInfo);
  },

  /**
   * Get filtered delivery data for conditions,
   * 1. selected time
   * 2. delivery method
   */
  getDeliveryOptions: (
    selectedDateTime,
    selectedDeliveryMethod,
    deliveryData,
    openTimes,
    scheduleDelay,
  ) => {
    const time = moment(selectedDateTime);

    switch (selectedDeliveryMethod) {
      case "DINEIN":
        return DeliveryUtils.__getDeliveryOptionsDINEIN(
          deliveryData,
          time,
          openTimes,
        );

      case "PICKUP":
        return DeliveryUtils.__getDeliveryOptionsPICKUP(
          deliveryData,
          time,
          openTimes,
        );

      case "DELIVER":
        return DeliveryUtils.__getDeliveryOptionsDELIVER(
          deliveryData,
          time,
          openTimes,
          scheduleDelay,
        );

      default:
        return {
          deliveryOptions: [],
          error: {
            reason: "NOT_IMPLEMENTED",
            message: "Unexpected error. Please contact support.",
          },
        };
    }
  },

  __getDeliveryOptionsDINEIN: (deliveryData, time, openTimes) => {
    const error = {};
    let deliveryOptions = [];
    const dataDineIn = deliveryData.find(
      (d) => d.type === DELIVERY_TYPE_DINEIN,
    );
    if (dataDineIn) {
      const isAvailable = TimeUtils.isTimeAvailable(
        time,
        dataDineIn.availability,
        openTimes,
      );
      if (isAvailable) {
        deliveryOptions = [dataDineIn];
      } else {
        error.reason = "NOT_AVAILABLE";
        error.message = "Dinein is not available for that time.";
      }
    } else {
      error.reason = "NO_OPTION";
      error.message = "No dinein option available.";
    }

    return { deliveryOptions, error };
  },

  __getDeliveryOptionsPICKUP: (deliveryData, time, openTimes) => {
    const error = {};
    let deliveryOptions = [];

    const pickupOptions = deliveryData.filter(
      (d) => d.type === DELIVERY_TYPE_PICKUP,
    );
    if (!_.isEmpty(pickupOptions)) {
      const availableOptions = pickupOptions.filter((option) =>
        TimeUtils.isTimeAvailable(time, option.availability, openTimes),
      );

      if (!_.isEmpty(availableOptions)) {
        deliveryOptions = [...pickupOptions];
      } else {
        const earliestAvailableTimesForOptions = pickupOptions.map((option) =>
          AvailabilityUtils.getEarliestAvailableTimeForDeliveryOption(
            option.availability,
            openTimes,
            moment(),
            0,
          ),
        );
        const earliestTime = moment.min(earliestAvailableTimesForOptions);
        error.reason = "NOT_AVAILABLE";
        const message = earliestTime
          .calendar()
          .replace("today at ", "")
          .replace("on ", "");
        error.message = `Sorry, pick-up starts from ${message} onwards.`;
      }
    } else {
      error.reason = "NO_OPTION";
      error.message = "Sorry, no pick-up option available.";
    }

    return { deliveryOptions, error };
  },

  __getDeliveryOptionsDELIVER: (
    deliveryData,
    time,
    openTimes,
    scheduleDelay,
  ) => {
    const error = {};
    let deliveryOptions = [];

    const deliveryList = deliveryData.filter(
      (d) => d.type.split(" ")[0] === DELIVERY_TYPE_DELIVER,
    );
    if (deliveryList) {
      const availableOptions = deliveryList.filter((del) =>
        TimeUtils.isTimeAvailableSchedule(
          time,
          del.availability,
          openTimes,
          scheduleDelay,
        ),
      );

      if (!_.isEmpty(availableOptions)) {
        deliveryOptions = [...availableOptions];
      } else {
        const earliestAvailableTimesForOptions = deliveryList.map((option) =>
          AvailabilityUtils.getEarliestAvailableTimeForDeliveryOption(
            option.availability,
            openTimes,
            moment(),
            scheduleDelay || 0,
          ),
        );
        const earliestTime = moment.min(earliestAvailableTimesForOptions);
        error.reason = "NOT_AVAILABLE";
        const message = earliestTime
          .calendar()
          .replace("today at ", "")
          .replace("on ", "");
        error.message = `Sorry, delivery starts from ${message} onwards.`;
      }
    } else {
      error.reason = "NO_OPTION";
      error.message = "Sorry, no delivery option available.";
    }

    return { deliveryOptions, error };
  },
};

export default DeliveryUtils;
