import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import queryString from "query-string";
import {
  useHistory,
  useRouteMatch,
} from "react-router-dom/cjs/react-router-dom.min";

import firebase from "../../../utils/firebase";
import { loginUserError, loginUserSuccess } from "../../../redux/actions/auth";
import { logInfo } from "../../../services/logging";
import { createCustomToken } from "../../../services/globalService";
import { setLocalStore } from "../../../utils/storage/localStorage";
import { CircularProgress, makeStyles } from "@material-ui/core";
import { QUERY_PARAM_PAYMENTS } from "../../../utils/constants";
import useQueryParam from "../../../hooks/RouteHooks/useQueryParam";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "400px",
  },
}));

const LoginPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { params } = useRouteMatch();
  const { addParams, getParams } = useQueryParam();

  const handleRedirect = () => {
    const paymentParam = getParams([QUERY_PARAM_PAYMENTS]);
    let newUrl;

    if (paymentParam?.[QUERY_PARAM_PAYMENTS]) {
      newUrl = {
        pathname: `/location/${params.locationId}/shop`,
        search: "?payInApp=true",
      };
    } else {
      newUrl = {
        pathname: `/location/${params.locationId}/shop`,
      };
    }
    console.log("sssssssssdfsdfsdf", paymentParam?.[QUERY_PARAM_PAYMENTS])
    // Update the browser's URL to the new URL constructed above
    history.push(newUrl);
  };

  const handleLogin = (firebaseUser) => {
    firebaseUser
      .getIdToken(true)
      .then((idToken) => {
        dispatch(
          loginUserSuccess(firebaseUser.displayName, firebaseUser.email),
        );
        setLocalStore("idToken", idToken);
        setLocalStore("userId", firebaseUser.uid);
        setLocalStore("photoURL", firebaseUser?.photoURL);

        logInfo({
          message: `Auth state changed: authentication success | user ${firebaseUser.uid}}`,
        });
      })
      .catch(() => {
        dispatch(loginUserError("Error login user"));
      });
    handleRedirect();
  };

  const handleAuthentication = async () => {
    try {
      const authFirebase = firebase.auth();
      const token = queryString.parse(window.location.search).t;
      const res = await createCustomToken(token);
      const customToken = res.data.customToken;
      const userCredential = await authFirebase.signInWithCustomToken(
        customToken,
      );

      if (userCredential) {
        handleLogin(userCredential);
      } else {
        dispatch(loginUserError("Error login user"));

        handleRedirect();
      }
    } catch {
      handleRedirect();
    }
  };

  useEffect(() => {
    const onAuthStateChange = async () => {
      const authFirebase = firebase.auth();
      authFirebase.onAuthStateChanged((firebaseUser) => {
        if (firebaseUser?.isAnonymous) {
          setLocalStore("anonymous", true);
        } else {
          setLocalStore("anonymous", false);
        }
        if (firebaseUser) {
          handleLogin(firebaseUser);
        } else {
          handleAuthentication();
        }
      });
    };
    onAuthStateChange();
  }, []);

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <CircularProgress disableShrink />
    </div>
  );
};

export default LoginPage;
