import React from "react";
import { Button, makeStyles, Typography } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import firebase from "firebase/app";
import "firebase/auth"; // Import Firebase Authentication for v8
import AlertMessage from "../../../../common/AlertMessage";
import { getLocalStore } from "../../../../../utils/storage/localStorage";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiAlert-icon": {
      display: "flex",
      alignItems: "center",
    },
  },
  underlineButton: {
    textDecoration: "underline",
    "&:hover": {
      textDecoration: "underline", // Ensure underline persists on hover
    },
  },
}));

const EmailActivationError = ({ setIsLoad }) => {
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertSeverity, setAlertSeverity] = React.useState("");
  const classes = useStyles();
  const anonymous = getLocalStore("anonymous");
  // Function to send verification email
  const handleSendEmailVerificationLink = async () => {
    const auth = firebase.auth(); // Get Firebase Auth instance
    const user = auth.currentUser; // Get currently logged-in user

    if (user) {
      try {
        await user.sendEmailVerification(); // Send the verification email
        setAlertMessage(
          "Email verification link sent! Please check your inbox.",
        );
        setAlertSeverity("success");
        setIsLoad(true);
      } catch (error) {
        setAlertMessage(error.message);
        setAlertSeverity("error");
      }
    } else {
      setAlertMessage("No user is signed in. Please log in first.");
      setAlertSeverity("error");
    }
  };

  return (
    <>
      <AlertMessage
        message={alertMessage}
        setMessage={() => setAlertMessage("")}
        severity={alertSeverity}
      />
      <div style={{ margin: "20px 20px 0px 20px" }}>
    <MuiAlert
          severity="error"
          elevation={6}
          variant="filled"
          className={classes.root}
        >
         
           <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
           <Typography>Email verification required</Typography>
           <Button
             onClick={handleSendEmailVerificationLink}
             size="small"
             className={classes.underlineButton}
             color="inherit"
           >
             Resend email verification link
           </Button>
         </div>
       
        </MuiAlert>
      </div>  
    </>
  );
};

export default EmailActivationError;
