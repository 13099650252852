import React, { useEffect, useState } from "react";

import { CircularProgress, Grid, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import CartItemCard from "../../components/@pages/orderStatus/CartItemCard";
import OrderStepper from "../../components/@pages/orderStatus/OrderStepper";
import OtherOrderDetails from "../../components/@pages/orderStatus/OtherOrderDetails";
import PageHeading from "../../components/@pages/orderStatus/PageHeading";
import PriceBreakdown from "../../components/@pages/orderStatus/PriceBeakdown";
import Header from "../../components/@pages/storeHome/MyOrder/Header";
import AlertMessage from "../../components/common/AlertMessage";
import BottomNavButtons from "../../components/common/BottomNavButtons";
import ButtonContained from "../../components/common/buttons/ButtonContained";
import StickyButton from "../../components/common/buttons/StickyButton";
import StickyBtnWrapper from "../../components/common/StickyBtnWrapper";
import Layout from "../../components/Layout";
import withInitDataFetch from "../../components/Layout/withFetch";
import ContentContainer from "../../containers/ContentContainer";
import ContentSection from "../../containers/ContentSection";
import PageWrapper from "../../containers/PageWrapper";
import SecondaryThemeProvider from "../../containers/SecondaryThemeProvider";
import { setIsOpenLoginModal } from "../../redux/actions/auth";
import { checkOrderPublic, completeOrder } from "../../services/orderService";
import RestaurantContactInfo from "./RestaurantContactInfo";
import LoginDialog from "../../components/@pages/storeHome/LoginDialog";
import { clearLocalStoreOnOrderSuccess } from "../../utils/storage/localStorage";
import { setCartItems } from "../../components/@pages/storeHome/MyOrder/redux/actions";
import { setSelectedPaymentMethod } from "../../components/@pages/payments/redux/actions";
import { setCustomerDetails } from "../../components/@pages/contact/redux/actions";
import { getCompleteOrderErrorMessage, getLocalStore } from "../../utils/utils";

const getStatusColor = (orderStatus) => {
  if (orderStatus === "new" || orderStatus === "pos") {
    return "#258AC2";
  }
  if (orderStatus === "accepted") {
    return "#CDA545";
  }
  if (orderStatus === "ready") {
    return "#88A548";
  }
  return "#258AC2";
};

const useStyles = makeStyles((theme) => ({
  OrderStatusPageWrapper: {
    minHeight: "95vh",
    [theme.breakpoints.down("xs")]: {
      minHeight: "none",
    },
    backgroundColor: "#fff",
  },
  itemListing: {
    maxHeight: 344,
    overflow: "auto",
    ...theme.palette.scrollbarStyles,
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
  orderDetails: {
    margin: theme.spacing(0, 2, 2, 2),
    // display: "flex",
    justifyContent: "space-around",
  },
  orderInfo: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },

  mainSection: {
    backgroundColor: theme.palette.background.accentColor,
    color: theme.palette.text.accentTextColor,
  },
}));

const WithDataLayout = withInitDataFetch(Layout);

const OrderSuccessPage = () => {
  const [orderStatus, setOrderStatus] = useState("new");
  const [alertMessage, setAlertMessage] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [orderData, setOrderData] = useState({});
  const [orderClient, setOrderClient] = useState(null);

  const classes = useStyles();
  const { params } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { storeInfo } = useSelector((state) => state.store);
  const [isDisabled, setIsDisabled] = useState(true);

  const storeCleanup = () => {
    clearLocalStoreOnOrderSuccess();
    dispatch(setCartItems([]));
    dispatch(setSelectedPaymentMethod(false));
    dispatch(
      setCustomerDetails({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        marketing: null,
      }),
    );
  };

  const handleSendCompleteRequest = async () => {
    const { orderId } = params;
    try {
      const res = await completeOrder(
        params.locationId,
        orderId,
        getCompleteOrderErrorMessage(
          storeInfo.businessDisplayName,
          storeInfo.contactNo,
        ),
      );
      setIsDisabled(false)
    } catch (error) {}
  };

  useEffect(() => {
    handleSendCompleteRequest();
    storeCleanup();
  }, []);

  useEffect(() => {
    window.onpopstate = () => {
      history.push({
        pathname: `/location/${params.locationId}/shop`,
        search: location.search,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => () => {
      if (orderClient) {
        orderClient.unsubscribe();
      }
    },
    [orderClient],
  );

  const subscribeConnection = () => {
    const client = new window.Ably.Realtime(
      process.env.REACT_APP_ABLY_PUBSUB_API_KEY,
    );

    client.connection.on("connected", () => {
      const { orderId } = params;
      const channel = client.channels.get(`order:${orderId}`);
      setOrderClient(channel);
      channel.subscribe((message) => {
        try {
          const { status } = JSON.parse(message.data);
          setOrderStatus(status);
        } catch (error) {
          setAlertMessage("Error fetching order status. ");
        }
      });
    });

    client.connection.on("failed", () => {
      setAlertMessage(
        "Error connecting to server. Please try reloading the page",
      );
    });
  };

  const {
    storeInfo: { businessDisplayName, contactNo, email, addressFormatted },
  } = useSelector((state) => state.store);

  useEffect(() => {
    const fetchOrderStatus = async () => {
      try {
        const { orderId, locationId } = params;
        const res = await checkOrderPublic(locationId, orderId);
        const { status, displayRef } = res.data.data;
        setOrderStatus(status);
        setOrderNumber(displayRef);
        setOrderData(res.data.data);
        subscribeConnection();
      } catch (error) {
        if (error?.response?.status === 400) {
          setAlertMessage("Order has been expired.");
          // return setOpenLoginDialog(true);
        }
        if (error?.response?.status === 401) {
          dispatch(setIsOpenLoginModal(true));
          // return setOpenLoginDialog(true);
        }
        setAlertMessage("Error fetching order details.");
      }
    };

    fetchOrderStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const {
    customer,
    deliveryAddress,
    deliveryCost,
    deliveryIsAsap,
    deliveryType,
    // discountIds,
    discountTotal,
    dueTime,
    items: cartItems,
    paymentAmount,
    paymentType,
    serviceCharge,
    note,
    extraCharges,
  } = orderData;

  if (_.isEmpty(orderData)) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "400px",
        }}
      >
        <CircularProgress disableShrink />
      </div>
    );
  }

  const redirectToHome = () => {
    history.push({
      pathname: `/location/${params.locationId}/shop`,
      search: location.search,
    });
  };

  return (
    <WithDataLayout>
      <PageWrapper>
        {/* <Prompt
        when
        message="You have unsaved changes, are you sure you want to leave?"
      /> */}
        <LoginDialog />
        <AlertMessage
          message={alertMessage}
          setMessage={setAlertMessage}
          severity="error"
        />
        <Hidden xsDown>
          <Grid item sm={4} xs={12} className={classes.orderInfo}>
            <Header isOrderStatusPage cartItemsOrdered={cartItems} />
            <div className={classes.itemListing}>
              {!_.isEmpty(cartItems) &&
                cartItems.map((item, i) => (
                  <CartItemCard key={item.menuId} cartItemData={item} idx={i} />
                ))}
            </div>
            <PriceBreakdown
              deliveryCharge={deliveryCost}
              totalDiscount={discountTotal}
              totalPaymentAmount={paymentAmount}
              serviceCharge={serviceCharge}
              deliveryType={deliveryType}
              extraCharges={extraCharges}
            />
            <OtherOrderDetails
              deliveryType={deliveryType}
              paymentType={paymentType}
              deliveryIsAsap={deliveryIsAsap}
              dueTime={dueTime}
              note={note}
            />
          </Grid>
        </Hidden>

        <SecondaryThemeProvider>
          <ContentSection>
            <ContentContainer noPadding isOrderStatusPage>
              {/* <Stepper activeStep={5} /> */}
              <OrderStepper
                orderStatus={orderStatus}
                statusColor={getStatusColor(orderStatus)}
                deliveryIsAsap={deliveryIsAsap}
              />
              <PageHeading
                orderNumber={orderNumber || "NA"}
                orderTime={dueTime}
                deliveryType={deliveryType}
                orderStatus={orderStatus}
                deliveryAddress={deliveryAddress}
                statusColor={getStatusColor(orderStatus)}
              />

              <RestaurantContactInfo
                deliveryAddress={deliveryAddress}
                customer={customer}
                deliveryType={deliveryType}
                businessDisplayName={businessDisplayName}
                addressFormatted={addressFormatted}
                contactNo={contactNo}
                email={email}
              />
              <Hidden xsDown>
                <div
                  style={{
                    padding: 16,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ButtonContained
                    onClick={redirectToHome}
                    style={{ width: "50%", padding: 16 }}
                    disabled={isDisabled}
                  >
                    Make new order
                  </ButtonContained>
                </div>
              </Hidden>
            </ContentContainer>
          </ContentSection>
        </SecondaryThemeProvider>

        <Hidden smUp>
          <StickyBtnWrapper>
            <BottomNavButtons
              handleBackward={null}
              handleContinue={null}
              orderData={orderData}
              continueButtonLabel="Continue"
            />
            <StickyButton
              onClick={redirectToHome}
              flex={1}
              variant="contained"
              color="secondary"
              style={{ width: "100%" }}
            >
              New order
            </StickyButton>
          </StickyBtnWrapper>
        </Hidden>
      </PageWrapper>
    </WithDataLayout>
  );
};

OrderSuccessPage.propTypes = {};
OrderSuccessPage.defaultProps = {};

export default OrderSuccessPage;
